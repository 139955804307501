<template>
  <div>
    <div class="topbar d-flex mt-2">
      <b-dropdown
        variant="white"
        toggle-class="btn btn-icon btn-icon-mobile w-auto btn-clean d-flex align-items-center btn-lg px-2 btn-hover-white mr-2"
        no-caret
        right
        no-flip
      >
        <template v-slot:button-content>
          <span class="svg-icon svg-icon-success svg-icon-lg">
            <inline-svg src="media/svg/icons/General/Notifications1.svg" />
          </span>
          <span class="navi-label mb-2">
            <span
              class="label label-light-danger label-rounded font-weight-bold"
              >0</span
            >
          </span>
        </template>
      </b-dropdown>
      <b-dropdown
        variant="white"
        toggle-class="btn btn-icon btn-icon-mobile w-auto btn-clean d-flex align-items-center btn-lg px-2 btn-hover-white"
        no-caret
        right
        no-flip
      >
        <template v-slot:button-content>
          <span
            class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"
            >Hi,</span
          >
          <span
            class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
            >{{ username_input }}</span
          >
          <span class="symbol symbol-lg-35 symbol-25 symbol-light-success">
            <span class="symbol-label font-size-h5 font-weight-bold">{{
              username_input[0]
            }}</span>
          </span>
        </template>
        <div class="navi navi-hover min-w-md-250px">
          <b-dropdown-text
            tag="div"
            class="navi-item font-weight-bold"
            @click="openDialogInfoUser"
          >
            <div class="btn btn-clean font-weight-bold btn-sm">
              <v-icon color="white">mdi-account</v-icon>
              Sửa thông tin
            </div>
          </b-dropdown-text>
          <b-dropdown-text
            tag="div"
            class="navi-separator mb-3 opacity-70"
          ></b-dropdown-text>
          <b-dropdown-text tag="div" class="navi-item font-weight-bold">
            <div
              class="btn btn-clean btn-sm font-weight-bold"
              href="#"
              @click="btnLogout"
            >
              <v-icon color="white">mdi-logout-variant</v-icon>
              Đăng xuất
            </div>
          </b-dropdown-text>
        </div>
      </b-dropdown>
    </div>

    <v-app id="dialog-user">
      <v-row justify="center">
        <v-dialog v-model="dialogInfoUser" max-width="800px" scrollable>
          <v-card>
            <v-card-title>
              <v-col cols="11">
                <span class="headline">Info User</span>
              </v-col>
              <v-col cols="1">
                <v-icon @click="dialogInfoUser = false" right>mdi-close</v-icon>
              </v-col>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="6" class="pb-0">
                    <v-text-field
                      label="Name" outlined dense
                      v-model="name_input"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" class="pb-0">
                    <v-text-field
                      label="Username" outlined dense
                      v-model="username_input"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <v-text-field
                      label="Email" outlined dense
                      type="email"
                      v-model="email_input"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <v-text-field
                      label="Phone" outlined dense
                      type="number"
                      v-model="phone_input"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      label="Address" outlined dense
                      v-model="address_input"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      label="Password" outlined dense
                      type="password"
                      v-model="password_input"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      label="Confirm Password" outlined dense
                      type="password"
                      v-model="confirm_password_input"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  outlined
                  class="btn text-uppercase mr-2"
                  style="color: #f64e60"
                  @click="dialogInfoUser = false"
              >
                Close
              </v-btn>
              <v-btn
                outlined
                class="btn btn-light-success text-uppercase mr-2"
                @click="btnUpdateInfoUser()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-app>
  </div>
</template>

<script>
import Auth from "@/core/services/auth";
import Ls from "@/core/services/jwt.service.js";
import ApiService from "@/service/api.service";
import Swal from "sweetalert2";
export default {
  name: "TopBar",
  components: {},
  data() {
    return {
      user_id: null,
      name_input: "",
      username_input: "",
      email_input: "",
      phone_input: "",
      address_input: "",
      password_input: "",
      confirm_password_input: "",
      dialogInfoUser: false,
    };
  },
  created() {
    this.getUserLogged();
  },
  methods: {
    btnLogout() {
      Auth.logout();
      this.$router.push("/login");
    },
    getUserLogged() {
      let user = JSON.parse(Ls.getUser());
      this.user_id = user.id;
      this.username_input = user.username;
      this.name_input = user.name;
      this.email_input = user.email;
      this.phone_input = user.phone == null ? "" : user.phone;
      this.address_input = user.address == null ? "" : user.address;
    },
    openDialogInfoUser() {
      (this.password_input = ""),
        (this.confirm_password_input = ""),
        (this.dialogInfoUser = true);
    },
    validateFormUser() {
      let flat = true;
      if (this.username_input == "" || this.username_input == undefined) {
        this.$toasted.error("Username is required !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.name_input == "" || this.name_input == undefined) {
        this.$toasted.error("Name is required !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.email_input == "" || this.email_input == undefined) {
        this.$toasted.error("Email is required !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.password_input != this.confirm_password_input) {
        this.$toasted.error(
          "Your password and confirmation password do not match !!",
          { theme: "toasted-primary", position: "top-right", duration: 4000 }
        );
        flat = false;
      }
      return flat;
    },
    btnUpdateInfoUser() {
      let vm = this;
      let validateForm = this.validateFormUser();
      if (!validateForm) {
        return;
      }
      let data = {
        username: this.username_input,
        name: this.name_input,
        email: this.email_input,
      };
      if (this.phone_input) data.phone = this.phone_input;
      if (this.address_input) data.address = this.address_input;
      if (this.password_input) data.password = this.password_input;
      Swal.fire({
        title: "Are you sure you want to save?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, do it!",
      }).then((result) => {
        if (result.value) {
          ApiService.post("prep-app/update-me", data)
            .then(function (res) {
              if ([200,202].includes(res.status)) {
                vm.$toasted.success("Cập nhật thông tin thành công !!", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                vm.actionUpdateLs();
                vm.dialogInfoUser = false;
              }
            })
            .catch(function (error) {
              if (error.response.status == 422) {
                let data_response_error =
                  error.response.data.error.message.split(".<br />");
                vm.showError(data_response_error);
              }
            });
        }
      });
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
    actionUpdateLs() {
      let user = JSON.parse(Ls.getUser());
      user.username = this.username_input;
      user.name = this.name_input;
      user.email = this.email_input;
      if (this.phone_input) user.phone = this.phone_input;
      if (this.address_input) user.address = this.address_input;
      Ls.saveUser(user);
    },
  },
};
</script>

<style lang="css">
#dialog-user {
  display: contents;
}
#dialog-user > .v-application--wrap {
  display: contents;
}
</style>
